import type {
  OrderQuery_order_Order,
  OrdersQuery_orders_OrderResponse_items_Order,
} from "~/graphql/propeller/generated";
import type { CustomerAddress } from "~/models/account";
import type { DiscountResponseItem } from "~/server/utils/bcClient";
import { SiteLocale } from "../graphql/datocms/generated";

export function formatAddress(address: any) {
  if (!address || address.code === "") {
    return "";
  }

  return `${address.lastName} ${address.firstName}<br>
          ${address.street} ${address.number} ${address.numberExtension}<br>
          ${address.postalCode} ${address.city} (${address.country})<br>
          ${address.phone}`;
}

export function formatAddressName(address: CustomerAddress): string {
  let addressName = `${address.street} ${address.number}`;

  if (address.numberExtension) {
    addressName += ` ${address.numberExtension}`;
  }

  addressName += `, ${address.postalCode} ${address.city}, ${address.countryCode}`;

  return addressName;
}

export function formatCurrency(value: number | undefined | null): string {
  const { locale } = useI18n();
  return formatCurrencyLocale(locale.value, value);
}

export function formatCurrencyLocale(locale: string, value: number | undefined | null): string {
  if (value === undefined || value === null) {
    return "?";
  }

  const { format } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  });

  return format(value);
}

export function formatDate(value: Date, options?: Intl.DateTimeFormatOptions): string {
  const { locale } = useI18n();

  const adjustedLocale = locale.value === SiteLocale.NlBe ? "nl-BE" : locale.value;
  const format = new Intl.DateTimeFormat(adjustedLocale, options).format;

  return format(value);
}

export function formatDecimal(value: number | undefined | null): string {
  // as it's used server side, useI18n is undefined
  return formatDecimalLocale("nl-BE", value);
}

export function formatDecimalLocale(locale: string, value: number | undefined | null) {
  if (value === undefined || value === null) {
    return "?";
  }

  const format = new Intl.NumberFormat(locale).format;

  return format(value);
}

export function formatDiscounts(discounts: Array<DiscountResponseItem>): string {
  const { t } = useI18n();

  return discounts
    .sort((a, b) => a.minimumQuantity - b.minimumQuantity || a.discountValue - b.discountValue)
    .map(d => formatDiscount(d, t))
    .join("<br />");
}

export function formatDiscount(discount: DiscountResponseItem, t: any): string {
  switch (discount.discountMethod) {
    case "amount-per-quantity":
      return t("product.discount.amount-per-quantity", {
        MinimumQuantity: discount.minimumQuantity,
        DiscountValue: formatCurrency(discount.discountValue),
      });
    case "amount-per-total":
      return t("product.discount.amount-per-total", {
        MinimumQuantity: discount.minimumQuantity,
        DiscountValue: formatCurrency(discount.discountValue),
      });
    case "percentage":
      return t("product.discount.percentage", {
        MinimumQuantity: discount.minimumQuantity,
        DiscountValue: discount.discountValue,
      });
    default:
      return `${discount.discountMethod} | MIN: ${discount.minimumQuantity} | DISC: ${discount.discountValue}`;
  }
}

export function formatPercentage(value: number): string {
  const { locale } = useI18n();

  if (value === 0) {
    return "";
  }

  const { format } = new Intl.NumberFormat(locale.value, {
    style: "percent",
    maximumFractionDigits: 2,
  });

  return format(value / 100);
}

export function formatProductSubTitle(year?: string | null, country?: string | null, region?: string | null) {
  let subTitle = "";

  if (year?.length) {
    subTitle += year;
  }

  if (year?.length && country?.length) {
    subTitle += " | ";
  }

  if (country?.length) {
    subTitle += country;
  }

  if (region?.length) {
    subTitle += `, ${region}`;
  }

  return subTitle;
}

export function getRoundedPrice(price: number, numberOfDecimals: number) {
  const powerValue = 10 ** numberOfDecimals;
  return Math.round(price * powerValue + 0.00000000000001) / powerValue;
};

export function getWebOrderIdFromOrder(
  order: OrderQuery_order_Order | OrdersQuery_orders_OrderResponse_items_Order,
): string {
  return getWebOrderIdFromOrderId(order.id);
}

export function getWebOrderIdFromOrderId(orderId: string | number): string {
  return `WEB-${orderId.toString()}`;
}

export function getValueOrDefault(
  value: string | null | undefined,
  defaultValue: string | undefined,
): string | undefined {
  if (!value || value.length === 0) {
    return defaultValue;
  }

  return value;
}
